module.exports = [{
      plugin: require('/Users/Andrew/Projects/temp-site-milaco/milaco/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144702057-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/Andrew/Projects/temp-site-milaco/milaco/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Andrew/Projects/temp-site-milaco/milaco/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
